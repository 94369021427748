<template>
  <div class="buy-sell-options">
    <form v-if="getUserDetails" class="form-options">
      <div class="buy-options">
        <h2 class="options-title">
          <div class="bid-green">
            <img
              alt="Buy options"
              src="@/assets/svg/v2/products/buyOrder.svg"
            />
          </div>
          {{ $t('settings.buySellPreferences.buying_options') }}
        </h2>
        <div class="setting-choices">
          <div class="setting-choice">
            <p>{{ $t('settings.buySellPreferences.buying_visibility') }}</p>
            <el-select
              v-model="getUserDetails.purchases_options.user_type_purchases"
              :placeholder="$t('commons.select')"
            >
              <el-option
                v-for="item in personTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
          <div
            v-if="
              getUserDetails.purchases_options.country_buying_includes
                .length === 0
            "
            class="setting-choice"
          >
            <p>{{ $t('settings.buySellPreferences.country_exclusion') }}</p>
            <el-select
              v-model="getUserDetails.purchases_options.country_buying_excludes"
              filterable
              multiple
              clearable
              :placeholder="$t('commons.select')"
            >
              <el-option
                v-for="item in purchaseCountryExclusion"
                :key="item.nom_en_gb"
                :label="item.nom_en_gb"
                :value="item.alpha2"
              />
            </el-select>
          </div>
          <div
            v-if="
              getUserDetails.purchases_options.country_buying_excludes
                .length === 0
            "
            class="setting-choice"
          >
            <p>{{ $t('settings.buySellPreferences.country_inclusion') }}</p>
            <el-select
              v-model="getUserDetails.purchases_options.country_buying_includes"
              filterable
              multiple
              clearable
              :placeholder="$t('commons.select')"
            >
              <el-option
                v-for="item in purchaseCountryIncludes"
                :key="item.nom_en_gb"
                :label="item.nom_en_gb"
                :value="item.alpha2"
              />
            </el-select>
          </div>
        </div>

        <p @click.prevent="copyBuyToSell()" class="copy-preferences">
          {{ $t('settings.buySellPreferences.preferences_copy') }}
        </p>
      </div>
      <div class="separator"></div>
      <div class="sell-options">
        <h2 class="options-title">
          <div class="ask-red">
            <img
              alt="Sell orders"
              src="@/assets/svg/v2/products/sellOrder.svg"
            />
          </div>
          {{ $t('settings.buySellPreferences.selling_options') }}
        </h2>
        <div class="setting-choices">
          <div class="setting-choice">
            <p>{{ $t('settings.buySellPreferences.selling_visibility') }}</p>
            <el-select
              v-model="getUserDetails.purchases_options.user_type_selling"
              :placeholder="$t('commons.select')"
            >
              <el-option
                v-for="item in personTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
          <div
            v-if="
              getUserDetails.purchases_options.country_selling_includes
                .length === 0
            "
            class="setting-choice"
          >
            <p>{{ $t('settings.buySellPreferences.country_exclusion') }}</p>
            <el-select
              v-model="
                getUserDetails.purchases_options.country_selling_excludes
              "
              filterable
              multiple
              clearable
              :placeholder="$t('commons.select')"
            >
              <el-option
                v-for="item in sellingCountryExclusion"
                :key="item.nom_en_gb"
                :label="item.nom_en_gb"
                :value="item.alpha2"
              />
            </el-select>
          </div>
          <div
            v-if="
              getUserDetails.purchases_options.country_selling_excludes
                .length === 0
            "
            class="setting-choice"
          >
            <p>{{ $t('settings.buySellPreferences.country_inclusion') }}</p>
            <el-select
              v-model="
                getUserDetails.purchases_options.country_selling_includes
              "
              filterable
              multiple
              clearable
              :placeholder="$t('commons.select')"
            >
              <el-option
                v-for="item in sellingCountryIncludes"
                :key="item.nom_en_gb"
                :label="item.nom_en_gb"
                :value="item.alpha2"
              />
            </el-select>
          </div>
        </div>
      </div>
      <div class="btn-submit">
        <button @click.prevent="buySellOptions()">
          {{ $t('commons.save') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { filter } from 'lodash'

export default {
  data() {
    return {
      options: {
        user_type_purchases: 0,
        user_type_selling: 0,
        country_buying_excludes: [],
        country_buying_includes: [],
        country_selling_excludes: [],
        country_selling_includes: [],
      },
      personTypes: [
        {
          label: this.$t('commons.all'),
          value: 0,
        },
        {
          label: this.$t('commons.individual'),
          value: 1,
        },
        {
          label: this.$t('commons.pro'),
          value: 2,
        },
      ],
    }
  },
  mounted() {
    if (!this.getUserDetails) {
      this.getClient()
    }
  },
  computed: {
    ...mapGetters({
      getLists: 'getLists',
      getUserDetails: 'getUserDetails',
    }),
    purchaseCountryExclusion() {
      return filter(
        this.getLists.country,
        (c) =>
          !this.getUserDetails.purchases_options.country_buying_includes.includes(
            c.alpha2
          )
      )
    },
    purchaseCountryIncludes() {
      return filter(
        this.getLists.country,
        (c) =>
          !this.getUserDetails.purchases_options.country_buying_excludes.includes(
            c.alpha2
          )
      )
    },
    sellingCountryExclusion() {
      return filter(
        this.getLists.country,
        (c) =>
          !this.getUserDetails.purchases_options.country_selling_includes.includes(
            c.alpha2
          )
      )
    },
    sellingCountryIncludes() {
      return filter(
        this.getLists.country,
        (c) =>
          !this.getUserDetails.purchases_options.country_selling_excludes.includes(
            c.alpha2
          )
      )
    },
  },
  methods: {
    ...mapActions({
      changeSellingBuyingPreferences: 'changeSellingBuyingPreferences',
      getClient: 'getAccountDetails',
    }),
    buySellOptions() {
      this.changeSellingBuyingPreferences(this.getUserDetails.purchases_options)
    },
    copyBuyToSell() {
      this.getUserDetails.purchases_options.country_selling_excludes =
        this.getUserDetails.purchases_options.country_buying_excludes
      this.getUserDetails.purchases_options.country_selling_includes =
        this.getUserDetails.purchases_options.country_buying_includes
      this.getUserDetails.purchases_options.user_type_selling =
        this.getUserDetails.purchases_options.user_type_purchases
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/styles/root.scss';

.form-options,
.buy-options,
.separator .sell-options,
.setting-choices,
.setting-choice,
.btn-submit button {
  display: flex;
}

.form-options,
.buy-options,
.sell-options,
.setting-choice {
  flex-direction: column;
}

.form-options {
  max-width: 676px;
  border: solid 1px rgba(255, 255, 255, 0.24);
  border-radius: 12px;
  background-color: #333333;
}

.buy-options,
.sell-options {
  padding: 20px;

  .copy-preferences {
    cursor: pointer;
    padding-left: 30px;
    font-size: 14px;
    margin-top: 10px;
  }
}

.buy-options {
  background: linear-gradient(
    to bottom,
    rgba(132, 204, 22, 0.35),
    rgba(132, 204, 22, 0)
  );
  border-radius: 12px;
  background-size: 100% 28%;
  background-repeat: no-repeat;
}

.sell-options {
  background: linear-gradient(
    to bottom,
    rgba(244, 63, 94, 0.35),
    rgba(244, 63, 94, 0)
  );
  background-size: 100% 28%;
  background-repeat: no-repeat;
}

.options-title {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;

  img {
    width: 20px;
    height: 20px;
  }
}

.bid-green {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #84cc16;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-left: 5px;
  margin-right: 13px;
}

.ask-red {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f43f5e;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-left: 5px;
  margin-right: 13px;
}

.separator {
  border-bottom: solid 1px rgba(255, 255, 255, 0.24);
  width: 100%;
}

.setting-choices {
  flex-wrap: wrap;
  gap: 15px;
  padding-left: 30px;
}

.setting-choice {
  width: 45%;
  justify-content: space-around;

  p {
    min-width: 45%;
    margin-bottom: 10px;
  }
}

.btn-submit {
  margin: auto;
  margin-bottom: 10px;

  button {
    align-items: center;
    border-radius: 52px;
    padding: 14px 20px;
    background: #2667ff;
    font-size: 16px;
  }
}

@media screen and (max-width: 500px) {
  .form-options {
    gap: 15px;
    margin: auto;
    margin-bottom: 24px;
    padding-top: 10px;
  }
  .buy-options,
  .sell-options {
    padding: 10px;
  }
  .options-title {
    margin-bottom: 20px;
  }
  .setting-choices {
    padding-left: 15px;
    gap: 20px;
  }
  .setting-choice {
    width: 80%;
  }
}
</style>
